import React from "react"
import { Link } from 'gatsby'

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="row">
      <h1>404 Not found</h1>
      <p>This page is not found. Back to <Link to="/">home</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
